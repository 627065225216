@import "tailwindcss/base";
@import "tailwindcss/components";
@import "./styles/buttons.css";
@import "./styles/form.css";
@import "./styles/styles.css";
@import "./styles/nav.css";
@import "./styles/header.css";
@import "./styles/table.css";
@import "tailwindcss/utilities";

